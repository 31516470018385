// @flow
import { APICore } from "./apiCore";
import i18n from "i18next";
import { VERSION } from "../../constants/version";
// AWS Amplify
import { Auth, API } from "aws-amplify";
import * as customQueries from "./graphql/auth.queries";
import env from "./env";

const api = new APICore();
// account
function login(params) {
  const getUserDetails = async (user) => {
    return await API.graphql({
      query: customQueries.getLoggedInUser,
      variables: { id: user.attributes.sub },
    });
  };

  return new Promise(function (resolve, reject) {
    Auth.signIn(params.username, params.password)
      .then((user) => {
        console.log("user", user);
        getUserDetails(user)
          .then((userDetails) => {
            const ddUser = userDetails.data.getUsers;

            const updatedUser = {
              id: user.attributes.sub,
              username: user.attributes.email,
              country: ddUser.country,
              firstName: ddUser.firstName,
              lastName: ddUser.lastName,
              email: user.attributes.email,
              permissions: ddUser.role.permissions,
              role: ddUser.role.roleName,
              roleId: ddUser.roleId,
              language: ddUser.language,
              type: ddUser.role.type,
              companyId: ddUser.companyId,
              companyName: ddUser.company.name,
              contactPerson: ddUser.company.contactPerson,
              notifications: JSON.parse(ddUser.notifications),
              productTourStatus: JSON.parse(ddUser.productTourStatus),
              settings: ddUser.settings && JSON.parse(ddUser.settings),
              version: VERSION,
            };
            resolve([200, updatedUser]);
          })
          .catch((error) => {
            console.error("Error", error);
            reject([
              401,
              { statusCode: 401, message: "Error in authentication." },
            ]);
          });
      })
      .catch((error) => {
        console.error("Error", error);
        reject([401, { statusCode: 401, message: "Error in authentication." }]);
      });
  });
}

function logout() {
  return new Promise(function (resolve, reject) {
    Auth.signOut()
      .then((_) => {
        resolve([200, {}]);
      })
      .catch((error) => {
        console.log("Error: ", error);
        reject([401, { statusCode: 401, message: "Error in authentication." }]);
      });
  });
}

function invitationSignup(params) {
  const setUser = async (user) => {
    const languageTemp = i18n.language.includes("-")
      ? i18n.language.split("-")[0]
      : i18n.language;
    const language = languageTemp === "de" ? "de" : "en";
    const newUser = {
      userId: user.userSub,
      invitationId: params.invitationId,
      newUser: {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        language: language,
      },
    };
    try {
      console.log(env);
      const API_ENDPOINT =
        "https://1g158ta64m.execute-api.eu-central-1.amazonaws.com/default/lineupdashboardUserRegistrationHandler-" +
        env;
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await fetch(
        API_ENDPOINT + "?data=" + JSON.stringify(newUser),
        requestOptions
      );
    } catch (error) {
      console.error("Error", error);
    }
  };

  return new Promise(function (resolve, reject) {
    let alert_message = "Username or password is incorrect";

    Auth.signUp({ username: params.email, password: params.password })
      .then((user) => {
        setUser(user);
        resolve([200, user]);
      })
      .catch((error) => {
        console.error("Error", error);
        switch (error.code) {
          case "InvalidPasswordException":
            alert_message =
              "Your selected password does not match the requirements. Please choose a new password with a minimum length of 8 characters.";
            break;
          case "UsernameExistsException":
            alert_message = "There is already a user with this email address";
            break;
          default:
            alert_message = "An error has occurred. Please try again.";
            break;
        }
        reject([400, { message: alert_message }]);
      });
  });
}

function signup(params) {
  const setUser = async (user) => {
    // Create User Document with User Id
    const newUser = {
      userId: user.userSub,
      companyName: params.companyName,
      newUser: {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
        language: i18n.language,
      },
    };
    try {
      const API_ENDPOINT =
        "https://1g158ta64m.execute-api.eu-central-1.amazonaws.com/default/lineupdashboardUserRegistrationHandler-" +
        env;
      var requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await fetch(
        API_ENDPOINT + "?data=" + JSON.stringify(newUser),
        requestOptions
      );
    } catch (error) {
      console.error("Error", error);
    }
  };

  return new Promise(function (resolve, reject) {
    Auth.signUp({ username: params.email, password: params.password })
      .then((user) => {
        setUser(user);
        resolve([200, user]);
      })
      .catch((error) => {
        console.error("Error", error);
        reject([403, { message: "Username or password is incorrect" }]);
      });
  });
}

function forgotPassword(params) {
  // const baseUrl = "/forget-password/";
  return new Promise(function (resolve, reject) {
    Auth.forgotPassword(params.username)
      .then((response) => {
        // Password reset email sent!
        let responseJson = {
          message:
            "We've sent you a link to reset your password to your registered email.",
        };
        resolve([200, responseJson]);
      })
      .catch((error) => {
        console.error("Error", error);
        reject([
          401,
          {
            message:
              "Sorry, we could not find any registered user with entered E-Mail.",
          },
        ]);
      });
  });
}

function newPassword(params) {
  // const baseUrl = "/forget-password/";
  return new Promise(function (resolve, reject) {
    Auth.forgotPasswordSubmit(params.email, params.code, params.newPassword)
      .then((response) => {
        // Success
        resolve([
          200,
          {
            message:
              "Successfully updated your password. You can now login again.",
          },
        ]);
      })
      .catch((error) => {
        console.error("Error", error);
        reject([
          401,
          {
            message:
              "An error accured while updating your password. Please try again.",
          },
        ]);
      });
  });
}

function verifyEmail(params) {
  return new Promise(function (resolve, reject) {
    Auth.confirmSignUp(params.email, params.code)
      .then((response) => {
        resolve([
          200,
          {
            message: "Successfully verified your E-Mail. You can now login.",
          },
        ]);
      })
      .catch((error) => {
        console.log("Error: ", error);
        reject([
          401,
          {
            message: "The link you have used has expired.",
          },
        ]);
      });
  });
}

function forgotPasswordConfirm(params) {
  const baseUrl = "/password/reset/confirm/";
  return api.create(`${baseUrl}`, params);
}

export {
  login,
  logout,
  signup,
  invitationSignup,
  forgotPassword,
  newPassword,
  verifyEmail,
  forgotPasswordConfirm,
};
